import * as React from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';

/* eslint-disable react/prop-types */
import GlyphInterfaceIcCInfo from '@dolstaff/shared/es/icons/GlyphInterfaceIcCInfo';
import cl from 'classnames';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { find, get } from 'lodash';
import styled from 'styled-components/macro';
import { IconStar } from './icons/IconStar';

import { Typography } from 'components/DesignSystemV2/Typography';
import { LazyImage } from 'components/LazyImage';
import { colorsV2 } from 'style/colors-v2';
import { useMounted } from 'utils/hooks/useMounted';

import { useTriggerOnDeviceType } from '../../components/Popup/useTriggerOnDeviceType';
import {
  deviceBreakpoints,
  deviceBreakpoints2
} from '../../utils/hooks/useDeviceQuery.hook';
import Certificates, { Images, InfoProps } from './Certificates';
import { HeadingContainer } from './HeadingContainer';
import Modal from './Modal';
import { SectionTitle } from '../Layout/SectionTitle';
import { toScreen } from '../../utils/media-query/responsive.util';

const infoType = {
  POPUP: 'popup',
  LINK: 'link',
  VIDEO: 'video'
};

const Differences = ({ differences }) => {
  return (
    <div>
      <HeadingContainer>
        <SectionTitle>
          {differences.heading.emphasize} {differences.heading.text}
        </SectionTitle>
      </HeadingContainer>
      <DifferenceList>
        {differences.differences
          .sort((diff1, diff2) => diff1.order - diff2.order)
          .map(diff => (
            <Difference key={diff.svgHandle} diff={diff} />
          ))}
      </DifferenceList>
    </div>
  );
};

Differences.propTypes = {
  differences: PropTypes.shape({
    heading: PropTypes.shape({
      emphasize: PropTypes.string,
      text: PropTypes.string
    }),
    differences: PropTypes.arrayOf(DiffType)
  })
};

const MarketingDifferences = Differences;

export default MarketingDifferences;

export const useLandingDifferences = () =>
  useStaticQuery(graphql`
    {
      gcms {
        jsonContanier(where: { name: "LandingCourse_Differences" }) {
          content
        }
      }
    }
  `);

export const GCMSDifferences = () => {
  const data = useLandingDifferences();
  return <MarketingDifferences differences={data.gcms.jsonContanier.content} />;
};

export const LinearThinkingPopoverWithData = props => {
  const data = useLandingDifferences();
  const differences = data.gcms.jsonContanier.content.differences;
  const linearDiff = find(
    differences,
    difference => difference.info && difference.info.type === 'popup'
  );
  const mounted = useMounted();

  if (!linearDiff || !mounted) {
    return null;
  }

  return (
    <LinearThinkingPopover
      diff={linearDiff}
      position={['bottom right']}
      {...props}
    />
  );
};

const StyledPopup = styled(Popup)`
  &-content {
    width: fit-content !important;
    .popup-arrow {
      color: ${colorsV2.black100};
    }
  }
`;

const PopupWrapper = styled.div`
  cursor: pointer;
  display: inline-grid;
  align-items: center;
  grid-auto-flow: column;
  gap: 8;
  &.need-expand-area {
    position: relative;
    z-index: 2;
    outline: none;
    display: flex;
    padding: 40px 24px;
    margin: -40px -24px -28px -24px;
    -webkit-tap-highlight-color: transparent;

    .icon {
      flex: 1;
      margin-left: 8px;
    }
  }
`;

const LinearThinkingPopover = ({
  diff,
  children = null,
  position = [
    'right center',
    'top right',
    'bottom right',
    'top left',
    'center center'
  ],
  needExpandArea,
  ...rest
}) => {
  const trigger = useTriggerOnDeviceType();

  return (
    <StyledPopup
      className="popover"
      arrow
      on={trigger}
      position={position}
      offsetY={needExpandArea ? -40 : 0}
      offsetX={needExpandArea ? -24 : 0}
      trigger={() => (
        <PopupWrapper className={cl({ 'need-expand-area': needExpandArea })}>
          {children}
          <span className="icon">
            <LazyImage handle="dE0rBMJyQpOWQmuSXvRQ" alt="icon"></LazyImage>
          </span>
        </PopupWrapper>
      )}
      closeOnDocumentClick
      {...rest}
    >
      <Certificates data={diff.info.content} />
    </StyledPopup>
  );
};

const DifferenceList = styled.div`
  display: grid;
  gap: 16px;

  ${toScreen(540)} {
    .image-container {
      img {
        width: 100%;
      }
    }
  }

  .video-link,
  .link,
  .link > * {
    color: ${colorsV2.primary100};
    margin-top: 12px;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  @media ${deviceBreakpoints2.sm} {
    grid-template-columns: 1fr 1fr;
    gap: 16px 24px;
  }

  @media ${deviceBreakpoints2.lg} {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 32px 24px;
  }
`;

const normalizeDifferencesHTML = htmlContent => {
  return (
    htmlContent
      // replace p with span
      .replace(/<p[^>]*>/g, '<span>')
      .replace(/<\/p>/g, '</span>')
      // remove div
      .replace(/<div[^>]*>/g, '')
      .replace(/<\/div>/g, '')
  );
};

const Difference = ({ diff }) => {
  const { imageHandle, title, htmlContent } = diff;
  const [showModal, setShowModal] = React.useState(false);

  const hasInfo = !!diff.info;
  const hasPopup = hasInfo && diff.info.type === infoType.POPUP;
  const hasLink = hasInfo && diff.info.type === infoType.LINK;
  const hasVideo = hasInfo && diff.info.type === infoType.VIDEO;

  const isYoutubeVideo = get(diff, 'info.content.link.url', '').includes(
    'youtu'
  );

  return (
    <div className="card-difference">
      <DifferenceContainer>
        <div className="image-container">
          <LazyImage
            imgProps={{
              alt: 'differences'
            }}
            handle={imageHandle}
          ></LazyImage>
        </div>
        <div className="text-container">
          <Title className={cl({ 'has-info': hasPopup })}>
            <Typography
              variant="semi-bold/16"
              tabletVariant="semi-bold/16"
              desktopVariant="semi-bold/20-28"
            >
              {title}
            </Typography>
          </Title>
        </div>

        <Typography
          type="span"
          variant="regular/14"
          style={{
            color: colorsV2.black80
          }}
          dangerouslySetInnerHTML={{
            __html: normalizeDifferencesHTML(htmlContent)
          }}
        />
      </DifferenceContainer>
      {hasPopup ? (
        <div>
          <div className="link" onClick={() => setShowModal(true)}>
            {diff.info.content.link.title}
          </div>
          <Modal
            title={
              diff.info.content.link.modalTitle || diff.info.content.link.title
            }
            isOpen={showModal}
            onRequestClose={() => setShowModal(false)}
          >
            <Images images={diff.info.content.images} />
          </Modal>
        </div>
      ) : null}

      {hasLink ? (
        <div className="link">
          <Link
            rel="noopener noreferrer"
            target="_blank"
            style={{ marginTop: 28 }}
            href={diff.info.content.link.url}
          >
            {diff.info.content.link.title}
          </Link>
        </div>
      ) : null}
      {hasVideo && (
        <VideoLink className="video-link">
          <div onClick={() => setShowModal(true)}>
            {diff.info.content.link.title}
          </div>
          <Modal
            title={
              isYoutubeVideo
                ? null
                : diff.info.content.link.modalTitle ||
                  diff.info.content.link.title
            }
            isOpen={showModal}
            onRequestClose={() => setShowModal(false)}
          >
            {isYoutubeVideo ? (
              <div className="iframe-container">
                <iframe
                  id="video-auto-play-modal"
                  src={diff.info.content.link.url}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            ) : (
              <video
                className="videoPlayer"
                controls
                preload="meta"
                autoPlay
                id="video-auto-play-modal"
                src={diff.info.content.link.url}
                type="video/mp4"
              >
                <track kind="captions" />
              </video>
            )}
          </Modal>
        </VideoLink>
      )}
    </div>
  );
};

const Title = styled.div`
  display: inline-grid;
  align-items: baseline;
`;

const DiffType = PropTypes.shape({
  svgHandle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  htmlContent: PropTypes.string.isRequired,
  order: PropTypes.number,
  info: InfoProps
});

Difference.propTypes = {
  diff: DiffType
};

LinearThinkingPopover.propTypes = {
  diff: DiffType,
  popoverPositions: PropTypes.array
};

const VideoLink = styled.div`
  margin-top: 12px;
  color: ${colorsV2.primary100};
  text-decoration: underline;
  cursor: pointer;
`;

const DifferenceContainer = styled.div`
  display: grid;
  gap: 4px;
  align-items: center;
  align-content: start;

  .content {
    grid-column: 1 / -1;
    max-width: inherit;
  }

  @media ${deviceBreakpoints.md} {
  }

  .text-container {
    p {
      width: 100%;
    }
  }
`;
