/* eslint-disable react/prop-types */
import * as React from 'react';

import { useKeenSlider } from 'keen-slider/react';
import styled from 'styled-components';

import BubbleTitle from 'shared/SchedulePage/BubbleTitle';
import { colorsV2 } from 'style/colors-v2';

import IconChevronUp from '../../SchedulePage/icons/IconChevronUp';

const IconChevronLeft = styled(IconChevronUp)`
  transform: rotate(-90deg);
`;

const IconChevronRight = styled(IconChevronUp)`
  transform: rotate(90deg);
`;

const Main = styled.section`
  .header {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    .section-title {
      margin-bottom: 0;
    }
    .navigate-carousel {
      display: grid;
      grid-auto-flow: column;
      align-items: center;
      gap: 10px;

      button {
        &:disabled {
          color: ${colorsV2.gray40};
        }
        padding: 0;
        border: none;
        background-color: transparent;
        min-width: 24px;
        min-height: 24px;
        width: 24px;
        height: 24px;
        display: grid;
        place-items: center;
        font-size: 24px;
        line-height: 0;
        color: ${colorsV2.black100};
        transition: 0.2s all;
        cursor: pointer;
        &:active {
          transform: scale(0.8);
        }
      }
    }
  }
  .slide {
    display: grid;
    place-items: center;
    padding-right: 16px;
    .slide-wrapper {
      position: relative;
      display: grid;
      place-items: center;
      width: 100%;
      padding-top: 100%;
      > div {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }
`;

const ResponsiveSectionCarousel = ({
  title,
  children,
  pageSize = 1.25,
  tabletPageSize = 3,
  desktopPageSize = 3
}) => {
  const [slideIndex, setSlideIndex] = React.useState(0);

  const [sliderRef, slider] = useKeenSlider({
    move: instance => setSlideIndex(instance.details().relativeSlide),
    slidesPerView: pageSize,
    spacing: 24,

    breakpoints: {
      '(min-width: 768px)': {
        slidesPerView: tabletPageSize
      },
      '(min-width: 1120px)': {
        slidesPerView: desktopPageSize
      }
    }
  });

  const onNext = () => {
    slider?.next();
  };

  const onPrev = () => {
    slider?.prev();
  };

  const [prevDisabled, setPrevDisabled] = React.useState(true);
  const [nextDisabled, setNextDisabled] = React.useState(false);

  React.useEffect(() => {
    if (slider && slider.details) {
      const isLastSlide =
        slideIndex + slider.details().slidesPerView >= slider.details().size;

      const isFirstSlide = slider.details().relativeSlide === 0;

      setPrevDisabled(isFirstSlide);
      setNextDisabled(isLastSlide);
    }
  }, [slider, slideIndex]);

  return (
    <Main className="responsive-carousel">
      {title && (
        <div className="header">
          <BubbleTitle data={[{ type: 'text', text: title }]} />
          <div className="navigate-carousel">
            <button disabled={prevDisabled} onClick={onPrev}>
              <IconChevronLeft />
            </button>
            <button disabled={nextDisabled} onClick={onNext}>
              <IconChevronRight />
            </button>
          </div>
        </div>
      )}

      <div ref={sliderRef} className="keen-slider">
        {children}
      </div>
    </Main>
  );
};

export default ResponsiveSectionCarousel;
